import "./style.css";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import * as dat from "dat.gui";

// Debug
//const gui = new dat.GUI();

const canvas = document.querySelector("canvas.webgl");
const scene = new THREE.Scene();

const geometry = new THREE.TorusGeometry(0.7, 0.2, 16, 100);
const ball = new THREE.SphereGeometry(0.7, 50, 50);
const ball2 = new THREE.SphereGeometry(0.4, 50, 50);

// Materials

const loader = new THREE.TextureLoader();

const material = new THREE.MeshBasicMaterial();
material.color = new THREE.Color(0x232323);

const material2 = new THREE.MeshBasicMaterial();
material2.color = new THREE.Color(0x01c9f2);

// Mesh
const sphere = new THREE.Mesh(geometry, material);
//scene.add(sphere);

const ballMesh = new THREE.Mesh(ball, material2);
const ballMesh2 = new THREE.Mesh(ball2, material);
const ballMesh3 = new THREE.Mesh(ball2, material);

ballMesh2.position.z = -1;
ballMesh2.position.y = 1;
ballMesh2.position.x = 1;

ballMesh3.position.z = -1;
ballMesh3.position.y = -1;
ballMesh3.position.x = -2;

scene.add(ballMesh);
scene.add(ballMesh2);
scene.add(ballMesh3);

// Lights
const area = new THREE.RectAreaLight(0xffffff, 0.5, 100, 100);
area.position.x = 5;
area.position.y = 5;
area.position.z = 10;
scene.add(area);

//gui.add(area.position, "y").min(0).max(10).step(0.01);

const pointLight = new THREE.PointLight(0xffffff, 0.5);
pointLight.position.x = 2;
pointLight.position.y = 3;
pointLight.position.z = 4;
scene.add(pointLight);

/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(50, sizes.width / sizes.height, 0.1, 100);
camera.position.x = 0;
camera.position.y = 0;
camera.position.z = 3;
scene.add(camera);

// Controls
// const controls = new OrbitControls(camera, canvas)
// controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  alpha: true,
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

/**
 * Animate
 */

let mouseX, mouseY;

let windowX = window.innerWidth / 2;
let windowY = window.innerHeight / 2;

const onMousemove = (event) => {
  mouseX = event.clientX - windowX;
  mouseY = event.clientY - windowY;
};

const onScroll = () => {
  var top = document.body.scrollTop;
  const containers = document.querySelectorAll(".parallax-container");
  for (let i = 0; i < containers.length; i++) {
    const element = containers[i];
    let positionY = document.body.scrollTop * element.dataset.parallax * 0.1;
    element.style.transform = "translate3d(0px, " + positionY + "px, 0px)";
  }
};

document.body.addEventListener("scroll", onScroll, false);
document.addEventListener("mousemove", onMousemove);

const clock = new THREE.Clock();

const tick = () => {
  const elapsedTime = clock.getElapsedTime();

  // Update objects
  sphere.rotation.y = 0.1 * elapsedTime;
  ballMesh.rotation.y = 0.1 * elapsedTime;

  ballMesh.position.z = Math.sin(180 + elapsedTime * 0.2) / 4;
  ballMesh2.position.z = Math.sin(elapsedTime * 0.3) / 2;
  ballMesh3.position.z = Math.sin(elapsedTime * 0.5) / 2 - 1;

  // ballMesh.position.x = -0.001 * mouseX;
  //ballMesh.position.z = -0.001 * mouseX * ballMesh.position.y;

  camera.position.y = 0.0002 * mouseY || 0;
  camera.position.x = 0.0002 * mouseX || 0;
  // Update Orbital Controls
  // controls.update()

  // Render
  renderer.render(scene, camera);

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();
